import $ from 'jquery';

$(function () {
	// Voor het modal image viewer deel
	const modal = $("#modal-image");
	const content = $(".modal-content", modal);
	const caption = $(".modal-caption", modal);

	$("img.zoomable")
		.on("mousedown", function () {
			modal.show();
			content.attr("src", this.src);
			caption.text(this.dataset.desc);
		});

	$(".modal-close", modal)
		.on("mousedown", function () {
			modal.hide();
		});

	$(document)
		.on("keydown", function (e) {
			if (e.keyCode === 27) {
				modal.hide();
			}
		});
});
